export const ADD_FILTER = 'ADD_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const ADD_WATCHED = 'ADD_WATCHED';
export const REMOVE_WATCHED = 'REMOVE_WATCHED';
export const ADD_SKIPPED = 'ADD_SKIPPED';
export const REMOVE_SKIPPED = 'REMOVE_SKIPPED';
export const SET_MAP_SELECTED_HERO = 'SET_MAP_SELECTED_HERO'
export const RESET_MAP_SELECTED_HERO = 'RESET_MAP_SELECTED_HERO'
export const COMPLETE_LANDING = 'COMPLETE_LANDING'
export const SET_ENTRY_LIST = 'SET_ENTRY_LIST'
export const SET_MIDDLE_LIST = 'SET_MIDDLE_LIST'
export const SET_FULL_LIST = 'SET_FULL_LIST'
