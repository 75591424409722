import { combineReducers } from 'redux';
import {
  ADD_FILTER,
  REMOVE_FILTER,
  ADD_WATCHED,
  REMOVE_WATCHED,
  ADD_SKIPPED,
  REMOVE_SKIPPED,
  SET_MAP_SELECTED_HERO,
  RESET_MAP_SELECTED_HERO,
  COMPLETE_LANDING,
  SET_ENTRY_LIST,
  SET_MIDDLE_LIST,
  SET_FULL_LIST
} from './actionTypes';
import { HEROS_BY_MOVIES, MOVIES } from '../Constants';

const initialMovies = Object.keys(HEROS_BY_MOVIES).reduce((acc, key) => {
  return {
    ...acc,
    [key]: { ...HEROS_BY_MOVIES[key], watched: false, skipped: false }
  }
}, {})

const initialHeroFilter = [];

const movies = (state = initialMovies, action) => {
  switch (action.type) {
    case ADD_WATCHED: {
      return {
        ...state,
        [action.payload]: { ...state[action.payload], watched: true }
      };
    }
    case REMOVE_WATCHED: {
      return {
        ...state,
        [action.payload]: { ...state[action.payload], watched: false }
      };
    }
    case ADD_SKIPPED: {
      return {
        ...state,
        [action.payload]: { ...state[action.payload], skipped: true }
      };
    }
    case REMOVE_SKIPPED: {
      return {
        ...state,
        [action.payload]: { ...state[action.payload], skipped: false }
      };
    }
    case SET_ENTRY_LIST: {
      return {
        ...state,
        [MOVIES.IRON_MAN_1]: { ...state[MOVIES.IRON_MAN_1], skipped: true },
        [MOVIES.IRON_MAN_2]: { ...state[MOVIES.IRON_MAN_2], skipped: true },
        [MOVIES.HULK_1]: { ...state[MOVIES.HULK_1], skipped: true },
        [MOVIES.THOR_1]: { ...state[MOVIES.THOR_1], skipped: true },
        [MOVIES.CAP_AMERICA_1]: { ...state[MOVIES.CAP_AMERICA_1], skipped: true },
        [MOVIES.AVENGERS_1]: { ...state[MOVIES.AVENGERS_1], skipped: false },
        [MOVIES.IRON_MAN_3]: { ...state[MOVIES.IRON_MAN_3], skipped: true },
        [MOVIES.THOR_2]: { ...state[MOVIES.THOR_2], skipped: true },
        [MOVIES.CAP_AMERICA_2]: { ...state[MOVIES.CAP_AMERICA_2], skipped: true },
        [MOVIES.AVENGERS_2]: { ...state[MOVIES.AVENGERS_2], skipped: false },
        [MOVIES.ANT_MAN_1]: { ...state[MOVIES.ANT_MAN_1], skipped: true },
        [MOVIES.DOCTOR_STRANGE]: { ...state[MOVIES.DOCTOR_STRANGE], skipped: true },
        [MOVIES.THOR_3]: { ...state[MOVIES.THOR_3], skipped: true },
        [MOVIES.CAP_AMERICA_3]: { ...state[MOVIES.CAP_AMERICA_3], skipped: false },
        [MOVIES.SPIDER_MAN_1]: { ...state[MOVIES.SPIDER_MAN_1], skipped: true },
        [MOVIES.BLACK_PANTHER_1]: { ...state[MOVIES.BLACK_PANTHER_1], skipped: true },
        [MOVIES.GUARDIAN_1]: { ...state[MOVIES.GUARDIAN_1], skipped: true },
        [MOVIES.GUARDIAN_2]: { ...state[MOVIES.GUARDIAN_2], skipped: true },
        [MOVIES.AVENGERS_3]: { ...state[MOVIES.AVENGERS_3], skipped: false },
        [MOVIES.ANT_MAN_2]: { ...state[MOVIES.ANT_MAN_2], skipped: true },
        [MOVIES.CAP_MARVEL_1]: { ...state[MOVIES.CAP_MARVEL_1], skipped: true },
        [MOVIES.AVENGERS_4]: { ...state[MOVIES.AVENGERS_4], skipped: false },
        [MOVIES.SPIDER_MAN_2]: { ...state[MOVIES.SPIDER_MAN_2], skipped: true },
      };
    }
    case SET_MIDDLE_LIST: {
      return {
        ...state,
        [MOVIES.IRON_MAN_1]: { ...state[MOVIES.IRON_MAN_1], skipped: true },
        [MOVIES.IRON_MAN_2]: { ...state[MOVIES.IRON_MAN_2], skipped: true },
        [MOVIES.HULK_1]: { ...state[MOVIES.HULK_1], skipped: true },
        [MOVIES.THOR_1]: { ...state[MOVIES.THOR_1], skipped: true },
        [MOVIES.CAP_AMERICA_1]: { ...state[MOVIES.CAP_AMERICA_1], skipped: true },
        [MOVIES.AVENGERS_1]: { ...state[MOVIES.AVENGERS_1], skipped: false },
        [MOVIES.IRON_MAN_3]: { ...state[MOVIES.IRON_MAN_3], skipped: false },
        [MOVIES.THOR_2]: { ...state[MOVIES.THOR_2], skipped: true },
        [MOVIES.CAP_AMERICA_2]: { ...state[MOVIES.CAP_AMERICA_2], skipped: false },
        [MOVIES.AVENGERS_2]: { ...state[MOVIES.AVENGERS_2], skipped: false },
        [MOVIES.ANT_MAN_1]: { ...state[MOVIES.ANT_MAN_1], skipped: false },
        [MOVIES.DOCTOR_STRANGE]: { ...state[MOVIES.DOCTOR_STRANGE], skipped: false },
        [MOVIES.THOR_3]: { ...state[MOVIES.THOR_3], skipped: false },
        [MOVIES.CAP_AMERICA_3]: { ...state[MOVIES.CAP_AMERICA_3], skipped: false },
        [MOVIES.SPIDER_MAN_1]: { ...state[MOVIES.SPIDER_MAN_1], skipped: false },
        [MOVIES.BLACK_PANTHER_1]: { ...state[MOVIES.BLACK_PANTHER_1], skipped: false },
        [MOVIES.GUARDIAN_1]: { ...state[MOVIES.GUARDIAN_1], skipped: false },
        [MOVIES.GUARDIAN_2]: { ...state[MOVIES.GUARDIAN_2], skipped: true },
        [MOVIES.AVENGERS_3]: { ...state[MOVIES.AVENGERS_3], skipped: false },
        [MOVIES.ANT_MAN_2]: { ...state[MOVIES.ANT_MAN_2], skipped: true },
        [MOVIES.CAP_MARVEL_1]: { ...state[MOVIES.CAP_MARVEL_1], skipped: false },
        [MOVIES.AVENGERS_4]: { ...state[MOVIES.AVENGERS_4], skipped: false },
        [MOVIES.SPIDER_MAN_2]: { ...state[MOVIES.SPIDER_MAN_2], skipped: true },
      };
    }
    case SET_FULL_LIST: {
      return {
        ...state,
        [MOVIES.IRON_MAN_1]: { ...state[MOVIES.IRON_MAN_1], skipped: false },
        [MOVIES.IRON_MAN_2]: { ...state[MOVIES.IRON_MAN_2], skipped: false },
        [MOVIES.HULK_1]: { ...state[MOVIES.HULK_1], skipped: false },
        [MOVIES.THOR_1]: { ...state[MOVIES.THOR_1], skipped: false },
        [MOVIES.CAP_AMERICA_1]: { ...state[MOVIES.CAP_AMERICA_1], skipped: false },
        [MOVIES.AVENGERS_1]: { ...state[MOVIES.AVENGERS_1], skipped: false },
        [MOVIES.IRON_MAN_3]: { ...state[MOVIES.IRON_MAN_3], skipped: false },
        [MOVIES.THOR_2]: { ...state[MOVIES.THOR_2], skipped: false },
        [MOVIES.CAP_AMERICA_2]: { ...state[MOVIES.CAP_AMERICA_2], skipped: false },
        [MOVIES.AVENGERS_2]: { ...state[MOVIES.AVENGERS_2], skipped: false },
        [MOVIES.ANT_MAN_1]: { ...state[MOVIES.ANT_MAN_1], skipped: false },
        [MOVIES.DOCTOR_STRANGE]: { ...state[MOVIES.DOCTOR_STRANGE], skipped: false },
        [MOVIES.THOR_3]: { ...state[MOVIES.THOR_3], skipped: false },
        [MOVIES.CAP_AMERICA_3]: { ...state[MOVIES.CAP_AMERICA_3], skipped: false },
        [MOVIES.SPIDER_MAN_1]: { ...state[MOVIES.SPIDER_MAN_1], skipped: false },
        [MOVIES.BLACK_PANTHER_1]: { ...state[MOVIES.BLACK_PANTHER_1], skipped: false },
        [MOVIES.GUARDIAN_1]: { ...state[MOVIES.GUARDIAN_1], skipped: false },
        [MOVIES.GUARDIAN_2]: { ...state[MOVIES.GUARDIAN_2], skipped: false },
        [MOVIES.AVENGERS_3]: { ...state[MOVIES.AVENGERS_3], skipped: false },
        [MOVIES.ANT_MAN_2]: { ...state[MOVIES.ANT_MAN_2], skipped: false },
        [MOVIES.CAP_MARVEL_1]: { ...state[MOVIES.CAP_MARVEL_1], skipped: false },
        [MOVIES.AVENGERS_4]: { ...state[MOVIES.AVENGERS_4], skipped: false },
        [MOVIES.SPIDER_MAN_2]: { ...state[MOVIES.SPIDER_MAN_2], skipped: false },
      };
    }
    default: {
      return state;
    }
  }
};

const heros = (state = initialHeroFilter, action) => {
  switch (action.type) {
    case ADD_FILTER: {
      return [...state, action.payload];
    }
    case REMOVE_FILTER: {
      return state.filter(hero => hero !== action.payload);
    }
    default: {
      return state;
    }
  }
};

const mapSelectedHero = (state = '', action) => {
  switch (action.type) {
    case SET_MAP_SELECTED_HERO: {
      return action.payload;
    }
    case RESET_MAP_SELECTED_HERO: {
      return '';
    }
    default: {
      return state;
    }
  }
}

const initialSettings = {
  landingCompleted: false,
}

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case COMPLETE_LANDING: {
      return { ...state, landingCompleted: true }
    }
    default: {
      return state;
    }
  }
}

export default combineReducers({ movies, heros, mapSelectedHero, settings });
