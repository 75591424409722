// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: 'AIzaSyCUt1HiqKtoaQOz_-G_K-8XHWaySr3ozdc',
  authDomain: 'depengers.firebaseapp.com',
  databaseURL: 'https://depengers.firebaseio.com',
  projectId: 'depengers',
  storageBucket: 'depengers.appspot.com',
  messagingSenderId: '1024849080669',
  appId: '1:1024849080669:web:067363bda26b0d88ad78b4',
  measurementId: 'G-R0G5GVPY95',
};
