import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  iconLink: {
    height: "4vh",
    backgroundColor: "#292929",
    margin: 'auto',
    display: 'block'
  }
}));

export function YoutubeIconLink(props) {
  const classes = useStyles();
  return (
    <div>
      <a target="_blank" rel="noopener noreferrer" href={getdYoutubeURL(props.title)} ><img src="/img/yt_logo_rgb_dark.png" alt={props.title} className={classes.iconLink} /></a>
    </div>
  )
}

function getdYoutubeURL(title) {
  return "https://www.youtube.com/results?search_query=" + title
}