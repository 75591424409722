import firebase from 'firebase/app';
import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer from './reducer';
import { getUser } from '../user';

const LOCAL_STORAGE_KEY = 'PERSISTKEY';
const localStorageDoc = {
  async set(obj) {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(obj));
  },
  async get() {
    const value = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    return {
      exists: value !== null,
      data() {
        return value;
      },
    };
  },
  async delete() {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  },
};

const getDoc = async () => {
  const db = firebase.firestore();
  const col = db.collection('users');
  const user = await getUser();
  // console.log('persist: using backend: ' + (user ? 'firestore' : 'localStorage'))
  return user ? col.doc(user.uid) : localStorageDoc;
};

const sleeper = async (obj) => {
  await new Promise((resolve) => setTimeout(resolve, 0));
  return obj;
};

const firestoreStorage = {
  setItem(key, item) {
    const newValue = JSON.parse(item);
    return getDoc()
      .then((doc) => doc.set({ [key]: newValue }))
      .then(() => {
        // console.log('setItem: ', key, newValue);
      })
      .then(sleeper);
  },
  getItem(key) {
    return getDoc()
      .then((doc) => doc.get())
      .then((doc) => {
        // console.log('getItem: ', key);
        const data = doc.exists ? doc.data() : '';
        // console.log('read data: ', data);
        return JSON.stringify(data[key]);
      })
      .then(sleeper);
  },
  removeItem(key) {
    return getDoc()
      .then((doc) => doc.delete())
      .then(() => {
        // console.log('removeItem: ', key);
      })
      .then(sleeper);
  },
};

// const persistedReducer = persistReducer({
// key: 'root',
// storage,
// }, rootReducer)

const firestoreReducer = persistReducer(
  {
    key: 'firestore',
    storage: firestoreStorage,
    // timeout: 100000
  },
  rootReducer
);

export default () => {
  // let store = createStore(persistedReducer)
  let store = createStore(firestoreReducer);
  let persistor = persistStore(store);
  return { store, persistor };
};
