import React, { useState } from 'react';
import CytoscapeComponent from 'react-cytoscapejs';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import { addWatched } from '../../redux/actions';
import { IMG_BY_MOVIE, MOVIES_BY_HEROS, EDGES, NODES } from '../../Constants';
import HeroSlider from './HeroSlider';

import { NetflixIconLink } from '../icon/NetflixIconLink';
import { AmazonPrimeIconLink } from '../icon/AmazonPrimeIconLink';
import { HuluIconLink } from '../icon/HuluIconLink';
import { YoutubeIconLink } from '../icon/YoutubeIconLink';

/**
 * @typedef {import('../../Constants').MovieID} MovieID
 * @typedef {import('../../Constants').MovieUrl} MovieUrl
 * @typedef {import('../../Constants').HeroID} HeroID
 * @typedef {import('../../Constants').HeroUrl} HeroUrl
 * @typedef {import('../../Constants').MovieEdge} MovieEdge
 */

const useStyles = makeStyles((theme) => ({
  imgContainer: {
    position: 'relative',
    backgroundColor: '#000',
    overflow: 'hidden',
  },
  movieTitle: {
    position: 'absolute',
    color: 'white',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    width: 'calc(100% - 88px)',
  },
  iconLink: {
    textAlign: 'center',
  },
  movieCover: {
    position: 'absolute',
    background: 'linear-gradient(to bottom,transparent,#222 70%,#000)',
    height: '25vh',
    bottom: '0',
    width: '100%',
  },
  thumbnail: {
    position: 'relative',
    // height: "60vh",
    width: '100%',
    margin: 'auto',
    // left: "50%",
    // transform: "translateX(-50%)",
  },
  youtubeIcon: {
    backgroundColor: '#292929',
    display: 'flex',
    alignItems: 'center',
    height: '7vh',
  },
  amazonPrimeIcon: {
    backgroundColor: '#FFF',
    display: 'flex',
    alignItems: 'center',
    height: '7vh',
  },
  netflixIcon: {
    backgroundColor: '#000',
    display: 'flex',
    alignItems: 'center',
    height: '7vh',
  },
  huluIcon: {
    backgroundColor: '#1ce783',
    display: 'flex',
    alignItems: 'center',
    height: '7vh',
  },
}));

function Map(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const xInterval = 200;
  const yInterval = 230;
  const nodes = NODES.map(({ data, position }) => ({
    data: { ...data, label: t(`movies.${data.id}`) },
    position: { x: position.x * xInterval, y: position.y * yInterval },
    locked: true,
    selectable: false,
  }));
  const edges = EDGES.map(({ source, target }) => ({
    data: { target, source },
  }));
  const elements = [...nodes, ...edges];
  const [cy, setCy] = useState(null);
  const [openMovie, setOpenMovie] = useState('');
  const edgeStyle = [
    ...nodes.map((n) => ({
      selector: `#${n.data.id}`,
      style: {
        'background-image': IMG_BY_MOVIE[n.data.id],
        // 'background-width': '100'
        'background-fit': 'contain',
      },
    })),
    {
      selector: 'node',
      style: {
        label: 'data(label)',
        'text-wrap': 'wrap',
        'text-max-width': '250px',
        'text-overflow-wrap': 'anywhere',
        color: '#dedede',
        shape: 'round-rectangle',
        width: 100,
        height: 135,
        'border-width': '3px',
        'border-color': 'darkgrey',
      },
    },
    {
      selector: 'edge',
      style: {
        width: '4px',
        'curve-style': 'taxi',
        'taxi-direction': 'downward',
        'taxi-turn-min-distance': '5px',
        'target-arrow-shape': 'triangle-backcurve',
        'arrow-scale': 3,
      },
    },
    {
      selector: 'edge[target="AVENGERS_1"]',
      style: {
        'taxi-turn': '-20%',
      },
    },
  ];

  if (props.mapSelectedHero) {
    console.debug(props.mapSelectedHero);
    console.debug(MOVIES_BY_HEROS[props.mapSelectedHero].movie);
    edgeStyle.push(
      ...MOVIES_BY_HEROS[props.mapSelectedHero].movie.map((movie) => ({
        selector: `node[id="${movie}"]`,
        style: {
          'border-width': '4px',
          'border-color': '#FC4158',
        },
      }))
    );
  }

  const handleCy = (cyObj) => {
    // Run only the first time
    if (cy === null) {
      // cyObj.fit()
      cyObj.on('tap', 'node', (evt) => {
        const movie = evt.target.id();
        // console.log(movie);
        setOpenMovie(movie);
      });
    }
    setCy(cyObj);
  };

  return (
    <div>
      <HeroSlider />
      <CytoscapeComponent
        elements={elements}
        style={{
          width: '100%',
          height: 'calc(100vh - 60px - 56px)',
          backgroundColor: '#434343',
        }}
        stylesheet={edgeStyle}
        cy={handleCy}
      />
      <Dialog
        onClose={() => {
          setOpenMovie('');
        }}
        open={openMovie !== ''}
      >
        <div className={classes.imgContainer}>
          <img
            src={IMG_BY_MOVIE[openMovie]}
            alt='Movie Thumbnail'
            className={classes.thumbnail}
          />
          <div className={classes.movieCover}></div>
          <Typography className={classes.movieTitle} variant='h5'>
            {t(`movies.${openMovie}`)}
          </Typography>
        </div>
        <Grid container justify='center'>
          <Grid item xs={6} md={3} lg={3} className={classes.netflixIcon}>
            <NetflixIconLink
              className={classes.iconLink}
              title={t(`movies.${openMovie}`)}
            ></NetflixIconLink>
          </Grid>
          <Grid item xs={6} md={3} lg={3} className={classes.amazonPrimeIcon}>
            <AmazonPrimeIconLink
              className={classes.iconLink}
              title={t(`movies.${openMovie}`)}
            ></AmazonPrimeIconLink>
          </Grid>
          <Grid item xs={6} md={3} lg={3} className={classes.youtubeIcon}>
            <YoutubeIconLink
              className={classes.iconLink}
              title={t(`movies.${openMovie}`)}
            ></YoutubeIconLink>
          </Grid>
          <Grid item xs={6} md={3} lg={3} className={classes.huluIcon}>
            <HuluIconLink
              className={classes.iconLink}
              title={t(`movies.${openMovie}`)}
            ></HuluIconLink>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}

export default connect(
  (state) => ({ mapSelectedHero: state.mapSelectedHero }),
  { addWatched }
)(Map);
