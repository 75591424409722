import React from 'react';
import ReactDOM from 'react-dom';
import './initFirebase';
import './index.css';
import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import configureStore from './redux/configureStore';
import { PersistGate } from 'redux-persist/integration/react';
import HelloStranger from './font/Hello-Stranger.ttf';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-5G89JX9',
};

TagManager.initialize(tagManagerArgs);

const { store, persistor } = configureStore();

const helloStranger = {
  fontFamily: 'HelloStranger',
  // fontStyle: 'normal',
  // fontDisplay: 'swap',
  // fontWeight: 400,
  src: `
    url(${HelloStranger}) format('truetype')
  `,
};

const theme = createMuiTheme({
  typography: {
    fontFamily: 'HelloStranger, Helvetica',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [helloStranger],
      },
    },
  },
});

ReactDOM.render(
  <Router>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Switch>
            <Route path='/app'>
              <App />
            </Route>
            <Route path='/' exact component={() => <Redirect to='/app' />} />
          </Switch>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
