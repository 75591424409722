import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '2rem',
    marginLeft: '1rem',
    marginRight: '1rem',
    textAlign: 'left',
  },
  sentence: {
    marginBottom: '2rem',
  },
}));

export default function Help() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <Typography component='h2' variant='h4' gutterBottom>
        {t(`text.whatIsDepengers`)}
      </Typography>
      <Typography variant='subtitle1' gutterBottom className={classes.sentence}>
        <p>{t(`text.whatIsDepengersText1`)}</p>
        <p>{t(`text.whatIsDepengersText2`)}</p>
        <p>{t(`text.whatIsDepengersText3`)}</p>
        <p>{t(`text.whatIsDepengersText4`)}</p>
        <p>{t(`text.whatIsDepengersText5`)}</p>
      </Typography>
      <Typography component='h2' variant='h4' gutterBottom>
        {t(`text.changeList`)}
      </Typography>

      <Typography variant='h6'>
        <Typography
          variant='subtitle1'
          gutterBottom
          className={classes.sentence}
        >
          {t(`text.changeListText1`)}
          <Link
            to='/app/select-list'
            component={RouterLink}
            color='textSecondary'
            underline='none'
          >
            {t(`text.changeListText2`)}
          </Link>
        </Typography>
      </Typography>
      <Typography component='h2' variant='h4' gutterBottom>
        {t(`text.contactUs`)}
      </Typography>
      <Typography variant='subtitle1' gutterBottom className={classes.sentence}>
        {t(`text.contactText1`)}
        <Link href='https://twitter.com/magmaslime' color='textSecondary'>
          Twitter
        </Link>
        {t(`text.contactText2`)}
      </Typography>
      <Typography component='h2' variant='h4' gutterBottom>
        {t(`text.developer`)}
      </Typography>
      <Typography variant='subtitle1' gutterBottom>
        magmaslime(
        <Link href='https://twitter.com/magmaslime' color='textSecondary'>
          @magmaslime
        </Link>
        )
      </Typography>
      <Typography variant='subtitle1' gutterBottom className={classes.sentence}>
        nshin(
        <Link href='https://twitter.com/wjoxsxXCxh9a0Ma' color='textSecondary'>
          @wjoxsxXCxh9a0Ma
        </Link>
        )
      </Typography>
      <Typography component='h2' variant='h4' gutterBottom>
        {t(`text.privacyPolicy`)}
      </Typography>
      <Typography component='h2' variant='h5' gutterBottom>
        {t(`text.aboutTool`)}
      </Typography>
      <Typography variant='subtitle1' gutterBottom className={classes.sentence}>
        {t(`text.aboutToolText1`)}
        <Link
          href='http://www.google.com/analytics/terms/jp.html'
          color='textSecondary'
        >
          {t(`text.aboutToolText2`)}
        </Link>
        {t(`text.aboutToolText3`)}
      </Typography>
      <Typography component='h2' variant='h5' gutterBottom>
        {t(`text.disclaimer`)}
      </Typography>
      <Typography
        variant='subtitle1'
        gutterBottom
        className={classes.sentence}
        style={{ whiteSpace: 'pre-line' }}
      >
        <p>
          {t(`text.disclaimerText1`)}
          <Link href='https://twitter.com/magmaslime' color='textSecondary'>
            {t(`text.disclaimerText2`)}
          </Link>
          {t(`text.disclaimerText3`)}
        </p>
        <p>{t(`text.disclaimerText4`)}</p>
        <p>{t(`text.disclaimerText5`)}</p>
        <p>{t(`text.disclaimerText6`)}</p>
      </Typography>
    </div>
  );
}
