import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import firebase from 'firebase/app';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import grey from '@material-ui/core/colors/grey';

import {setOnAuthStateChanged, setOnPendingRedirectChanged, isPendingRedirect, startFirebaseUI} from '../user'

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  avatarImg: {
    // padding: '1px',
    borderColor: grey[700],
    borderWidth: '3px',
    borderStyle: 'solid',
    borderRadius: theme.spacing(6),
  },
  pendingRedirectLoader: {
    paddingTop: '4px',
  }
}))

export default (props) => {
  const classes = useStyles();
  const [loginDialog, setLoginDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser] = useState(firebase.auth().currentUser);
  const [pendingRedirect, setPendingRedirect] = useState(isPendingRedirect());

  const history = useHistory();

  setOnAuthStateChanged(setUser);
  setOnPendingRedirectChanged(setPendingRedirect);

  const signOut = () => {
    firebase.auth().signOut();
    history.go(0);
  }

  return (
    <div style={{ maxHeight: '56px' }}>
      {user ? (
        <>
          <Avatar aria-controls='user-menu' onClick={(e) => setAnchorEl(e.currentTarget)} className={classes.avatar} classes={{img: classes.avatarImg}} src={user?.photoURL ?? 'unknown'} alt={user?.displayName ?? 'avatar'} />
          <Menu id='user-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={()=>setAnchorEl(null)}>
            <MenuItem>{user?.displayName}</MenuItem>
            <Button onClick={signOut}>Logout</Button>
          </Menu>
        </>
      ) :
      pendingRedirect ? (
        <>
          <div className={classes.pendingRedirectLoader}>
            <CircularProgress color='secondary' size="24px" />
          </div>
        </>
      ) :
       (
        <>
          <Button onClick={() => setLoginDialog(true)} disabled={props.disabled}>Login</Button>
          <Dialog open={loginDialog} onEntered={() => startFirebaseUI('#firebaseui-auth-container')} onClose={() => setLoginDialog(false)}>
            <div id='firebaseui-auth-container'></div>
          </Dialog>
        </>
      )}
    </div>
  );
};
