import {
  ADD_FILTER,
  REMOVE_FILTER,
  ADD_WATCHED,
  REMOVE_WATCHED,
  ADD_SKIPPED,
  REMOVE_SKIPPED,
  SET_MAP_SELECTED_HERO,
  RESET_MAP_SELECTED_HERO,
  COMPLETE_LANDING,
  SET_ENTRY_LIST,
  SET_MIDDLE_LIST,
  SET_FULL_LIST
} from './actionTypes';
import { createAction } from '@reduxjs/toolkit';

export const addFilter = createAction(ADD_FILTER);

export const removeFilter = createAction(REMOVE_FILTER);

export const addWatched = createAction(ADD_WATCHED);

export const removeWatched = createAction(REMOVE_WATCHED);

export const addSkipped = createAction(ADD_SKIPPED);

export const removeSkipped = createAction(REMOVE_SKIPPED);

export const setMapSelectedHero = createAction(SET_MAP_SELECTED_HERO);

export const resetMapSelectedHero = createAction(RESET_MAP_SELECTED_HERO);

export const completeLanding = createAction(COMPLETE_LANDING);

export const setEntryList = createAction(SET_ENTRY_LIST);

export const setMiddleList = createAction(SET_MIDDLE_LIST);

export const setFullList = createAction(SET_FULL_LIST);