import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  iconLink: {
    height: "100%",
    backgroundColor: "#000",
  },
  iconContainer: {
    height: '100%',
    margin: 'auto',
  }
}));

export function NetflixIconLink(props) {
  const classes = useStyles();
  return (
    <div className={classes.iconContainer}>
      <a target="_blank" rel="noopener noreferrer" href={getNetflixURL(props.title)} ><img src="/img/Netflix_Logo_RGB.png" alt={props.title} className={classes.iconLink} /></a>
    </div>
  )
}

function getNetflixURL(title) {
  return "https://www.netflix.com/search?q=" + title
}