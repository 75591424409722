import React from 'react';
import { connect } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  createMuiTheme,
  ThemeProvider,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import Link from '@material-ui/core/Link';

import {
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  Redirect,
  Link as RouterLink,
} from 'react-router-dom';
import Main from './components/views/Main';
import Map from './components/views/Map';
import Help from './components/views/Help';
import Landing from './components/views/Landing';
import GetStarted from './components/views/GetStarted';
import SelectList from './components/views/SelectList';
import LoginAvatar from './components/LoginAvatar';

const ltgothic = {
  fontFamily: 'LogoTypeGothic',
  src: `
  url('https://cdn.leafscape.be/logotype/logotype_web.woff2')
  format("woff2")
  `,
};

const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        main: grey[900],
      },
      secondary: {
        main: '#FC4158',
      },
      text: {
        secondary: '#f48fb1',
      },
    },
    typography: {
      fontFamily: [
        'LogoTypeGothic',
        'Arial',
        'sans-serif',
        '"Helvetica Neue"',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [ltgothic],
        },
      },
    },
  })
);

function App(props) {
  const history = useHistory();
  const match = useRouteMatch();
  const tabDisabled = ['landing', 'get-started', 'select-list'].some((path) =>
    history.location.pathname.endsWith(path)
  );
  const tabFound = ['/app', '/app/map', '/app/help'].includes(
    history.location.pathname
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar>
        <Toolbar>
          <Link
            to='/app'
            component={RouterLink}
            color='textPrimary'
            underline='none'
          >
            <Typography variant='h6'>Depengers</Typography>
          </Link>
          <div style={{ flexGrow: 1 }} />
          <Tabs
            value={tabFound ? history.location.pathname : '/app'}
            indicatorColor={tabFound ? 'secondary' : 'primary'}
            onChange={(event, value) => {
              history.push(value);
            }}
          >
            <Tab value='/app' label='Home' disabled={tabDisabled} />
            <Tab value='/app/map' label='Map' disabled={tabDisabled} />
            <Tab
              value='/app/help'
              icon={<HelpOutlineRoundedIcon />}
              disabled={tabDisabled}
            />
          </Tabs>
          <LoginAvatar />
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Switch>
        <Route path={`${match.url}/`} exact>
          {props.settings.landingCompleted ? (
            <Main />
          ) : (
            <Redirect to={`${match.url}/landing`} />
          )}
        </Route>
        <Route path={`${match.url}/map`} exact>
          {props.settings.landingCompleted ? (
            <Map />
          ) : (
            <Redirect to={`${match.url}/landing`} />
          )}
        </Route>
        <Route path={`${match.url}/help`} exact>
          {props.settings.landingCompleted ? (
            <Help />
          ) : (
            <Redirect to={`${match.url}/landing`} />
          )}
        </Route>
        <Route path={`${match.url}/landing`} exact>
          {props.settings.landingCompleted ? (
            <Redirect to={`${match.url}/`} />
          ) : (
            <Landing />
          )}
        </Route>
        <Route path={`${match.url}/get-started`} exact>
          {props.settings.landingCompleted ? (
            <Redirect to={`${match.url}/`} />
          ) : (
            <GetStarted />
          )}
        </Route>
        <Route path={`${match.url}/select-list`} exact>
          <SelectList />
        </Route>
      </Switch>
    </ThemeProvider>
  );
}

export default connect((state) => ({ settings: state.settings }))(App);
