import React from 'react'
import Slider from "react-slick"
import { connect } from 'react-redux'
import { Avatar, ButtonBase } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import {addWatched, setMapSelectedHero, resetMapSelectedHero} from "../../redux/actions"
import {IMG_BY_HEROS} from "../../Constants"

const useStyles = makeStyles(theme => ({
  heroSlider: {
    width: 'calc(100vw - 60px)',
    padding: '10px 5px',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: '#e1e1e1'
  },
  avatarButton: {
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)'
  },
  prevArrow: {},
  nonSelectedHero: {
    opacity: "0.5"
  }
}))

function HeroSlider(props) {
  const classes = useStyles();
  const settings = {
    dots: false,
    infinite: true,
    centerMode: true,
    slidesToShow: 3,
    variableWidth: true,
    slidesToScroll: 1,
    swipeToSlide: true,
  }
  return (<Slider {...settings} className={classes.heroSlider}>
      {Object.entries(IMG_BY_HEROS).map(([hero, img]) => {
        const selected = props.mapSelectedHero === hero
        return (<div key={hero} style={{width: '60px'}}>
          <ButtonBase focusRipple={true} className={classes.avatarButton} onClick={()=>{
              if (selected) {
                props.resetMapSelectedHero()
              } else {
                props.setMapSelectedHero(hero)
              }
            }}>
            <Avatar src={img} classes={props.mapSelectedHero === '' || selected ? {} : {img: classes.nonSelectedHero}} />
          </ButtonBase>
        </div>)
      })}
    </Slider>)
}

export default connect(state => ({mapSelectedHero: state.mapSelectedHero}), { addWatched, setMapSelectedHero, resetMapSelectedHero })(HeroSlider)