import firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';

let resolveFirstAuthStateChangeOccured;
let firstAuthStateChangeOccured = false;
let firstAuthStateChangeOccuredPromise = new Promise((resolve) => {
  resolveFirstAuthStateChangeOccured = resolve;
});
let firebaseUser = firebase.auth().currentUser;
const firebaseUI = new firebaseui.auth.AuthUI(firebase.auth());
const db = firebase.firestore();
const userInfoCol = db.collection('userInfo')

export const startFirebaseUI = (id) => {
  // Initialize the FirebaseUI Widget using Firebase.
  firebaseUI.start(id, {
    callbacks: {
      signInSuccessWithAuthResult: function (authResult, redirectUrl) {
        // console.log('signin success', authResult, redirectUrl);
        const { isNewUser, providerId, username } = authResult.additionalUserInfo;
        userInfoCol.doc(authResult.user.uid).set({
          additionalUserInfo: { isNewUser, providerId, username }
        });

        // User successfully signed in.
        // Return type determines whether we continue the redirect automatically
        // or whether we leave that to developer to handle.
        return false;
      },
      uiShown: function () {
        // The widget is rendered.
        // Hide the loader.
        // console.log('uiShown');
        // document.getElementById('loader').style.display = 'none'
      },
    },
    signInSuccessUrl: '/app/login',
    signInOptions: [
      // List of OAuth providers supported.
      firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    ],
  });
};

let onAuthStateChanged = (val) => {};
let onPendingRedirectChanged = (val) => {};

export const setOnAuthStateChanged = (func) => {
  onAuthStateChanged = func;
};
export const setOnPendingRedirectChanged = (func) => {
  onPendingRedirectChanged = func;
};

let resolvePendingRedirect;
let pendingRedirect = false;
let pendingRedirectPromise = new Promise((resolve, reject) => {
  if (firebaseUI.isPendingRedirect()) {
    resolvePendingRedirect = resolve;
    pendingRedirect = true;
    onPendingRedirectChanged(pendingRedirect);
    return startFirebaseUI('#firebaseui-pending-redirect');
  } else {
    resolve(false);
  }
  // console.log('PendingRedirect: ', pendingRedirect)
});

export const isPendingRedirect = () => {
  return pendingRedirect;
};

firebase.auth().onAuthStateChanged(
  (user) => {
    if (user) {
      // console.log('onAuthStateChanged, logged in: ', user);
      firebaseUser = user;
      if (pendingRedirect) {
        resolvePendingRedirect(false);
        pendingRedirect = false;
        onPendingRedirectChanged(pendingRedirect);
      }
    } else {
      // console.log('onAuthStateChanged, Not logged in');
      firebaseUser = null;
    }
    if (!firstAuthStateChangeOccured) {
      firstAuthStateChangeOccured = true;
      resolveFirstAuthStateChangeOccured();
    }
    onAuthStateChanged(firebaseUser);
  },
  (error) => {
    // console.log('onAuthStateChanged, error: ', error);
  },
  (completed) => {
    // console.log('onAuthStateChanged, completed: ', completed);
  }
);

export const getUser = async () => {
  await Promise.all([
    pendingRedirectPromise,
    firstAuthStateChangeOccuredPromise,
  ]);
  return firebaseUser;
};
