import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, SvgIcon, Fab } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { IMG_BY_MOVIE } from '../../Constants';
import { NetflixIconLink } from '../icon/NetflixIconLink';
import { AmazonPrimeIconLink } from '../icon/AmazonPrimeIconLink';
import { HuluIconLink } from '../icon/HuluIconLink';
import { YoutubeIconLink } from '../icon/YoutubeIconLink';
import {
  makeStyles,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import { addWatched, removeWatched } from '../../redux/actions';
import Slider from 'react-slick';

const buttonTheme = createMuiTheme({
  palette: { primary: { main: 'rgba(255,255,255,0.25)' } },
});

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: 'calc(100vh - 56px)',
    backgroundColor: '#000',
  },
  imgContainer: {
    position: 'relative',
    width: '40vh',
    backgroundColor: '#000',
    overflow: 'hidden',
  },
  filmIcon: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  movieTitle: {
    position: 'absolute',
    color: 'white',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    width: 'calc(100% - 88px)',
  },
  movieCover: {
    position: 'absolute',
    background: 'linear-gradient(to bottom,transparent,#222 70%,#000)',
    height: '25vh',
    bottom: '0',
    width: '100%',
  },
  iconLink: {
    textAlign: 'center',
  },
  thumbnail: {
    position: 'relative',
    height: '60vh',
    margin: 'auto',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  youtubeIcon: {
    backgroundColor: '#292929',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '7vh',
  },
  amazonPrimeIcon: {
    backgroundColor: '#FFF',
    display: 'flex',
    alignItems: 'center',
    height: '7vh',
  },
  netflixIcon: {
    backgroundColor: '#000',
    display: 'flex',
    alignItems: 'center',
    height: '7vh',
  },
  huluIcon: {
    backgroundColor: '#1ce783',
    display: 'flex',
    alignItems: 'center',
    height: '7vh',
  },
}));

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        // top: 'calc(50% - (40px - 20px) / 2 )',
        right: '15px',
        zIndex: '1',
      }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        left: '15px',
        // top: 'calc(50% - (40px - 20px) / 2 )',
        zIndex: '1',
      }}
      onClick={onClick}
    />
  );
}

export function FilmIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d='M 4 4 L 4 138 L 183 138 L 183 4 L 4 4'
        fill='none'
        stroke='currentcolor'
        strokeWidth='8'
        strokeMiterlimit='10'
        pointerEvents='stroke'
      />
      <path
        d='M 63 80 L 83 100'
        fill='none'
        stroke='currentcolor'
        strokeWidth='8'
        strokeMiterlimit='10'
        pointerEvents='stroke'
      />
      <path
        d='M 78 100 L 128 50'
        fill='none'
        stroke='currentcolor'
        strokeWidth='8'
        strokeMiterlimit='10'
        pointerEvents='stroke'
      />
      <rect
        x='148'
        y='78'
        width='20'
        height='15'
        strokeWidth='5'
        pointerEvents='all'
      />
      <rect
        x='148'
        y='108'
        width='20'
        height='15'
        strokeWidth='5'
        pointerEvents='all'
      />
      <rect
        x='148'
        y='18'
        width='20'
        height='15'
        strokeWidth='5'
        pointerEvents='all'
      />
      <rect
        x='148'
        y='48'
        width='20'
        height='15'
        strokeWidth='5'
        pointerEvents='all'
      />
      <rect
        x='18'
        y='78'
        width='20'
        height='15'
        strokeWidth='5'
        pointerEvents='all'
      />
      <rect
        x='18'
        y='108'
        width='20'
        height='15'
        strokeWidth='5'
        pointerEvents='all'
      />
      <rect
        x='18'
        y='18'
        width='20'
        height='15'
        strokeWidth='5'
        pointerEvents='all'
      />
      <rect
        x='18'
        y='48'
        width='20'
        height='15'
        strokeWidth='5'
        pointerEvents='all'
      />
    </SvgIcon>
  );
}

function Main(props) {
  const [titles, setTitles] = useState(Object.keys(props.watchList)); // [IRON_MAN_1, IRON_MAN_2, ...]
  const [movieIndex, setMovieIndex] = useState(titles[0]);
  const classes = useStyles();
  const { t } = useTranslation();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    adaptiveHeight: true,
    variableWidth: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    afterChange: (index) => setMovieIndex(titles[index]),
  };

  useEffect(() => {
    // Update titles only on first render
    setTitles(
      Object.keys(props.watchList).sort(
        (a, b) => props.watchList[a].watched - props.watchList[b].watched
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setMovieIndex(titles[0]);
  }, [titles]);

  return (
    <div className={classes.mainContainer}>
      {/* <Grid container direction='columns' justify='space-between' alignItems="center" className={classes.mainContainer}>
        <Grid item > */}
      <Slider {...settings}>
        {titles.map((title) => {
          const movie = props.watchList[title];
          return (
            <div key={movie.title}>
              <div className={classes.imgContainer}>
                <img
                  src={IMG_BY_MOVIE[movie.title]}
                  alt='Movie Thumbnail'
                  className={classes.thumbnail}
                />
                <div className={classes.movieCover}></div>
                <Typography className={classes.movieTitle} variant='h5'>
                  {t(`movies.${movie.title}`)}
                </Typography>
                <MuiThemeProvider theme={buttonTheme}>
                  <Fab
                    color='primary'
                    aria-label='Film'
                    className={classes.filmIcon}
                    onClick={() => {
                      movie.watched
                        ? props.removeWatched(movie.title)
                        : props.addWatched(movie.title);
                    }}
                  >
                    <FilmIcon
                      htmlColor={movie.watched ? '#FC4158' : '#d1d1d1'}
                      viewBox='-0.5 -23 187 187'
                      fontSize='large'
                    />
                  </Fab>
                </MuiThemeProvider>
                {/* <Fab color="primary" aria-label="Film" >
                  <FilmIcon htmlColor="#d1d1d1" viewBox="-0.5 -23 187 187" fontSize="large" />
                </Fab> */}
              </div>
            </div>
          );
        })}
      </Slider>
      {/* </Grid> */}
      {/* <Grid item> */}
      <Grid container justify='center'>
        <Grid item xs={6} md={3} lg={3} className={classes.netflixIcon}>
          <NetflixIconLink
            className={classes.iconLink}
            title={t(`movies.${props.watchList[movieIndex].title}`)}
          ></NetflixIconLink>
        </Grid>
        <Grid item xs={6} md={3} lg={3} className={classes.amazonPrimeIcon}>
          <AmazonPrimeIconLink
            className={classes.iconLink}
            title={t(`movies.${props.watchList[movieIndex].title}`)}
          ></AmazonPrimeIconLink>
        </Grid>
        <Grid item xs={6} md={3} lg={3} className={classes.youtubeIcon}>
          <YoutubeIconLink
            className={classes.iconLink}
            title={t(`movies.${props.watchList[movieIndex].title}`)}
          ></YoutubeIconLink>
        </Grid>
        <Grid item xs={6} md={3} lg={3} className={classes.huluIcon}>
          <HuluIconLink
            className={classes.iconLink}
            title={t(`movies.${props.watchList[movieIndex].title}`)}
          ></HuluIconLink>
        </Grid>
      </Grid>
      {/* </Grid> */}
      {/* </Grid> */}
    </div>
  );
}

export default connect(
  (state) => ({
    /* { IRON_MAN_1: {
        heros: [HEROS.IRON_MAN],
        title: IRON_MAN_1,
        watched: false,
        skipped: false
      }
    } */
    watchList: Object.keys(state.movies)
      .map((title) => ({ ...state.movies[title], title }))
      .filter((movie) => !movie.skipped)
      .reduce((accumulator, movie) => {
        return {
          ...accumulator,
          [movie.title]: movie,
        };
      }, {}),
  }),
  { addWatched, removeWatched }
)(Main);
