import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  iconLink: {
    height: "100%",
    backgroundColor: "#FFF",
  },
  iconContainer: {
    height: '100%',
    margin: 'auto',
  }
}));

export function AmazonPrimeIconLink(props) {
  const classes = useStyles();
  return (
    <div className={classes.iconContainer}>
      <a target="_blank" rel="noopener noreferrer" href={getAmazonPrimeURL(props.title)} ><img src="/img/ROW-Prime-Video-Color-Black.jpg" alt={props.title} className={classes.iconLink} /></a>
    </div>
  )
}

function getAmazonPrimeURL(title) {
  return "https://www.amazon.co.jp/s?k=" + title
}