/**
 * @typedef {string} MovieID
 * @typedef {string} MovieUrl
 * @typedef {string} HeroID
 * @typedef {string} HeroUrl
 * @typedef {{source: MovieID, target: MovieID}} MovieEdge
 * @typedef {Object.<HeroID, {movie: MovieID[]}>} MoviesByHeros
 */

/**
 * Defines movie identifiers.
 * @type {Object.<string, MovieID>}
 */
export const MOVIES = {
  IRON_MAN_1: 'IRON_MAN_1',
  IRON_MAN_2: 'IRON_MAN_2',
  HULK_1: 'HULK_1',
  THOR_1: 'THOR_1',
  CAP_AMERICA_1: 'CAP_AMERICA_1',
  AVENGERS_1: 'AVENGERS_1',
  IRON_MAN_3: 'IRON_MAN_3',
  THOR_2: 'THOR_2',
  CAP_AMERICA_2: 'CAP_AMERICA_2',
  AVENGERS_2: 'AVENGERS_2',
  ANT_MAN_1: 'ANT_MAN_1',
  DOCTOR_STRANGE: 'DOCTOR_STRANGE',
  THOR_3: 'THOR_3',
  CAP_AMERICA_3: 'CAP_AMERICA_3',
  SPIDER_MAN_1: 'SPIDER_MAN_1',
  BLACK_PANTHER_1: 'BLACK_PANTHER_1',
  GUARDIAN_1: 'GUARDIAN_1',
  GUARDIAN_2: 'GUARDIAN_2',
  AVENGERS_3: 'AVENGERS_3',
  ANT_MAN_2: 'ANT_MAN_2',
  CAP_MARVEL_1: 'CAP_MARVEL_1',
  AVENGERS_4: 'AVENGERS_4',
  SPIDER_MAN_2: 'SPIDER_MAN_2',
};

export const MOVIE_TITLES = Object.keys(MOVIES);

/**
 * Defines hero identifiers.
 * @type {Object.<string, HeroID>}
 */
export const HEROS = {
  IRON_MAN: 'IRON_MAN',
  HULK: 'HULK',
  THOR: 'THOR',
  CAP_AMERICA: 'CAP_AMERICA',
  ANT_MAN: 'ANT_MAN',
  DOCTOR_STRANGE: 'DOCTOR_STRANGE',
  SPIDER_MAN: 'SPIDER_MAN',
  BLACK_PANTHER: 'BLACK_PANTHER',
  CAP_MARVEL: 'CAP_MARVEL',
  STAR_LORD: 'STAR_LORD',
};

/**
 * Defines heros found in each movie.
 * @type {Object.<MovieID, {hero: HeroID[]}>}
 */
export const HEROS_BY_MOVIES = {
  [MOVIES.IRON_MAN_1]: {
    hero: [HEROS.IRON_MAN],
  },
  [MOVIES.IRON_MAN_2]: {
    hero: [HEROS.IRON_MAN],
  },
  [MOVIES.HULK_1]: {
    hero: [HEROS.HULK],
  },
  [MOVIES.CAP_AMERICA_1]: {
    hero: [HEROS.CAP_AMERICA],
  },
  [MOVIES.THOR_1]: {
    hero: [HEROS.THOR],
  },
  [MOVIES.AVENGERS_1]: {
    hero: [HEROS.IRON_MAN, HEROS.HULK, HEROS.THOR, HEROS.CAP_AMERICA],
  },
  [MOVIES.IRON_MAN_3]: {
    hero: [HEROS.IRON_MAN],
  },
  [MOVIES.THOR_2]: {
    hero: [HEROS.THOR],
  },
  [MOVIES.CAP_AMERICA_2]: {
    hero: [HEROS.CAP_AMERICA],
  },
  [MOVIES.AVENGERS_2]: {
    hero: [HEROS.IRON_MAN, HEROS.HULK, HEROS.THOR, HEROS.CAP_AMERICA],
  },
  [MOVIES.ANT_MAN_1]: {
    hero: [HEROS.ANT_MAN],
  },
  [MOVIES.DOCTOR_STRANGE]: {
    hero: [HEROS.DOCTOR_STRANGE],
  },
  [MOVIES.THOR_3]: {
    hero: [HEROS.THOR, HEROS.HULK],
  },
  [MOVIES.CAP_AMERICA_3]: {
    hero: [HEROS.IRON_MAN, HEROS.CAP_AMERICA, HEROS.BLACK_PANTHER],
  },
  [MOVIES.SPIDER_MAN_1]: {
    hero: [HEROS.SPIDER_MAN],
  },
  [MOVIES.BLACK_PANTHER_1]: {
    hero: [HEROS.BLACK_PANTHER],
  },
  [MOVIES.GUARDIAN_1]: {
    hero: [HEROS.STAR_LORD],
  },
  [MOVIES.GUARDIAN_2]: {
    hero: [HEROS.STAR_LORD],
  },
  [MOVIES.AVENGERS_3]: {
    hero: [HEROS.IRON_MAN, HEROS.HULK, HEROS.THOR, HEROS.CAP_AMERICA],
  },
  [MOVIES.ANT_MAN_2]: {
    hero: [HEROS.ANT_MAN],
  },
  [MOVIES.CAP_MARVEL_1]: {
    hero: [HEROS.CAP_MARVEL],
  },
  [MOVIES.AVENGERS_4]: {
    hero: [HEROS.IRON_MAN, HEROS.HULK, HEROS.THOR, HEROS.CAP_AMERICA],
  },
  [MOVIES.SPIDER_MAN_2]: {
    hero: [HEROS.SPIDER_MAN],
  },
};

/**
 * Converts HEROS_BY_MOVIES to a dictionary of hero name to an array of movie names
 * @returns {MoviesByHeros}
 */
function getMoviesByHeros() {
  return Object.entries(HEROS_BY_MOVIES).reduce((acc, val) => {
    const [movie, v] = val;
    v.hero.forEach((hero) => {
      if (!acc.hasOwnProperty(hero)) {
        acc[hero] = { movie: [movie] };
      } else if (!acc[hero].movie.includes(hero)) {
        acc[hero].movie.push(movie);
      }
    });
    return acc;
  }, {});
}

/**
 * Defines movies from each heros.
 * @type {MoviesByHeros}
 */
export const MOVIES_BY_HEROS = getMoviesByHeros();

/**
 * Defines movie dependencies.
 * @type {MovieEdge[]}
 */
export const EDGES = [
  { source: MOVIES.IRON_MAN_1, target: MOVIES.IRON_MAN_2 },
  { source: MOVIES.IRON_MAN_2, target: MOVIES.AVENGERS_1 },
  { source: MOVIES.THOR_1, target: MOVIES.AVENGERS_1 },
  { source: MOVIES.HULK_1, target: MOVIES.AVENGERS_1 },
  { source: MOVIES.CAP_AMERICA_1, target: MOVIES.AVENGERS_1 },
  { source: MOVIES.AVENGERS_1, target: MOVIES.IRON_MAN_3 },
  { source: MOVIES.AVENGERS_1, target: MOVIES.THOR_2 },
  { source: MOVIES.AVENGERS_1, target: MOVIES.CAP_AMERICA_2 },
  { source: MOVIES.IRON_MAN_3, target: MOVIES.AVENGERS_2 },
  { source: MOVIES.THOR_2, target: MOVIES.AVENGERS_2 },
  { source: MOVIES.CAP_AMERICA_2, target: MOVIES.AVENGERS_2 },
  { source: MOVIES.AVENGERS_2, target: MOVIES.ANT_MAN_1 },
  { source: MOVIES.AVENGERS_2, target: MOVIES.THOR_3 },
  { source: MOVIES.DOCTOR_STRANGE, target: MOVIES.THOR_3 },
  { source: MOVIES.ANT_MAN_1, target: MOVIES.CAP_AMERICA_3 },
  { source: MOVIES.CAP_AMERICA_3, target: MOVIES.SPIDER_MAN_1 },
  { source: MOVIES.SPIDER_MAN_1, target: MOVIES.AVENGERS_3 },
  { source: MOVIES.THOR_3, target: MOVIES.AVENGERS_3 },
  { source: MOVIES.BLACK_PANTHER_1, target: MOVIES.AVENGERS_3 },
  { source: MOVIES.GUARDIAN_1, target: MOVIES.GUARDIAN_2 },
  { source: MOVIES.GUARDIAN_2, target: MOVIES.AVENGERS_3 },
  { source: MOVIES.CAP_MARVEL_1, target: MOVIES.AVENGERS_4 },
  { source: MOVIES.AVENGERS_3, target: MOVIES.AVENGERS_4 },
  { source: MOVIES.ANT_MAN_1, target: MOVIES.ANT_MAN_2 },
  { source: MOVIES.ANT_MAN_2, target: MOVIES.AVENGERS_4 },
  { source: MOVIES.AVENGERS_4, target: MOVIES.SPIDER_MAN_2 },
  { source: MOVIES.SPIDER_MAN_1, target: MOVIES.SPIDER_MAN_2 },
];

/**
 * @type {{data: {id: MovieID}, position: {x: number, y: number}}[]}
 */
export const NODES = [
  { data: { id: MOVIES.IRON_MAN_1 }, position: { x: 0, y: 0 } },
  { data: { id: MOVIES.IRON_MAN_2 }, position: { x: 0, y: 1 } },
  { data: { id: MOVIES.HULK_1 }, position: { x: 1, y: 0 } },
  { data: { id: MOVIES.THOR_1 }, position: { x: 2, y: 0 } },
  { data: { id: MOVIES.CAP_AMERICA_1 }, position: { x: -1, y: 0 } },
  { data: { id: MOVIES.AVENGERS_1 }, position: { x: 0, y: 2 } },
  { data: { id: MOVIES.IRON_MAN_3 }, position: { x: 0, y: 3 } },
  { data: { id: MOVIES.THOR_2 }, position: { x: 1, y: 3 } },
  { data: { id: MOVIES.CAP_AMERICA_2 }, position: { x: -1, y: 3 } },
  { data: { id: MOVIES.AVENGERS_2 }, position: { x: 0, y: 4 } },
  { data: { id: MOVIES.ANT_MAN_1 }, position: { x: -2, y: 5 } },
  { data: { id: MOVIES.DOCTOR_STRANGE }, position: { x: 1, y: 4 } },
  { data: { id: MOVIES.THOR_3 }, position: { x: 0, y: 5 } },
  { data: { id: MOVIES.CAP_AMERICA_3 }, position: { x: -1, y: 6 } },
  { data: { id: MOVIES.SPIDER_MAN_1 }, position: { x: -1, y: 7 } },
  { data: { id: MOVIES.BLACK_PANTHER_1 }, position: { x: 2, y: 7 } },
  { data: { id: MOVIES.GUARDIAN_1 }, position: { x: 1, y: 6 } },
  { data: { id: MOVIES.GUARDIAN_2 }, position: { x: 1, y: 7 } },
  { data: { id: MOVIES.AVENGERS_3 }, position: { x: 0, y: 8 } },
  { data: { id: MOVIES.ANT_MAN_2 }, position: { x: -2, y: 8 } },
  { data: { id: MOVIES.CAP_MARVEL_1 }, position: { x: 1, y: 8 } },
  { data: { id: MOVIES.AVENGERS_4 }, position: { x: 0, y: 9 } },
  { data: { id: MOVIES.SPIDER_MAN_2 }, position: { x: -1, y: 10 } },
];

/**
 * @type {MovieID}
 */
export const GRAPH_ROOT = MOVIES.SPIDER_MAN_2;

/** * Defines movie thumbnail urls.
 * @type {Object.<MovieID, MovieUrl>}
 */
export const IMG_BY_MOVIE = {
  IRON_MAN_1:
    'https://images-na.ssl-images-amazon.com/images/I/81RorIYKoBL._LX450_.jpg',
  IRON_MAN_2:
    'https://images-na.ssl-images-amazon.com/images/I/81FtCBG+nPL._LX450_.jpg',
  HULK_1:
    'https://images-na.ssl-images-amazon.com/images/I/91y5u5zFyWL._LX450_.jpg',
  THOR_1:
    'https://images-na.ssl-images-amazon.com/images/I/81KF7gCPR5L._LX450_.jpg',
  CAP_AMERICA_1:
    'https://images-na.ssl-images-amazon.com/images/I/81O57QORO7L._LX450_.jpg',
  AVENGERS_1:
    'https://images-na.ssl-images-amazon.com/images/I/91ORhWqix1L._LX300_.jpg',
  IRON_MAN_3:
    'https://images-na.ssl-images-amazon.com/images/I/91mZbQq0osL._LX450_.jpg',
  THOR_2:
    'https://images-na.ssl-images-amazon.com/images/I/A1yaXh1pBBL._LX450_.jpg',
  CAP_AMERICA_2:
    'https://images-na.ssl-images-amazon.com/images/I/91htnwkgYyL._LX450_.jpg',
  AVENGERS_2:
    'https://images-na.ssl-images-amazon.com/images/I/917AYgshbsL._LX300_.jpg',
  ANT_MAN_1:
    'https://images-na.ssl-images-amazon.com/images/I/81Fr4x1W-HL._LX300_.jpg',
  DOCTOR_STRANGE:
    'https://images-na.ssl-images-amazon.com/images/I/A1ETnS3mj6L._LX450_.jpg',
  THOR_3:
    'https://images-na.ssl-images-amazon.com/images/I/A1thgEKWwSL._LX450_.jpg',
  CAP_AMERICA_3:
    'https://images-na.ssl-images-amazon.com/images/I/A1Ad+Zo0+9L._LX450_.jpg',
  SPIDER_MAN_1:
    'https://images-na.ssl-images-amazon.com/images/I/A1kdFbB-yYL._LX300_.jpg',
  BLACK_PANTHER_1:
    'https://images-na.ssl-images-amazon.com/images/I/91T6GiOCInL._LX450_.jpg',
  GUARDIAN_1:
    'https://images-na.ssl-images-amazon.com/images/I/A1rDcaxXz4L._LX300_.jpg',
  GUARDIAN_2:
    'https://images-na.ssl-images-amazon.com/images/I/A1y+IZTn37L._LX300_.jpg',
  AVENGERS_3:
    'https://images-na.ssl-images-amazon.com/images/I/A1Gey6UW8CL._LX300_.jpg',
  ANT_MAN_2:
    'https://images-na.ssl-images-amazon.com/images/I/91yE5KyM1wL._LX300_.jpg',
  CAP_MARVEL_1:
    'https://images-na.ssl-images-amazon.com/images/I/91lJ94zTZwL._LX300_.jpg',
  AVENGERS_4:
    'https://images-na.ssl-images-amazon.com/images/I/91Q0cUYL2-L._LX300_.jpg',
  SPIDER_MAN_2:
    'https://images-na.ssl-images-amazon.com/images/I/91ANGbCz6+L._LX300_.jpg',
};

/**
 * Defines hero avatar urls.
 * @type {Object.<HeroID, HeroUrl>}
 */
export const IMG_BY_HEROS = {
  IRON_MAN:
    'https://marvel.disney.co.jp/content/dam/marvel/character/1002_ironman/1002_ironman_tn.jpg',
  HULK:
    'https://marvel.disney.co.jp/content/dam/disney/characters/marvel/1005_hulk/1005_hulk_tn.jpg',
  THOR:
    'https://marvel.disney.co.jp/content/dam/marvel/character/1004_thor/1004_thor_tn.jpg',
  CAP_AMERICA:
    'https://marvel.disney.co.jp/content/dam/marvel/character/1003_captainamerica/1003_captainamerica_tn.jpg',
  ANT_MAN:
    'https://marvel.disney.co.jp/content/dam/marvel/character/1018_antman/1018_antman_tn.jpg',
  DOCTOR_STRANGE:
    'https://marvel.disney.co.jp/content/dam/disney/characters/marvel/avengers-iw/1666_doctorstrange.jpg',
  SPIDER_MAN:
    'https://marvel.disney.co.jp/content/dam/marvel/character/1001_spiderman/1001_spiderman_tn.jpg',
  BLACK_PANTHER:
    'https://marvel.disney.co.jp/content/dam/marvel/character/1019_blackpanther/1019_blackpanther_tn.jpg',
  CAP_MARVEL:
    'https://marvel.disney.co.jp/content/dam/disney/characters/marvel/captain-marvel/9001_captain-marvel.jpg',
  STAR_LORD:
    'https://marvel.disney.co.jp/content/dam/marvel/character/1012_starlord/1012_starlord_tn.jpg',
};
