import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { red } from '@material-ui/core/colors';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  bgImage: {
    // height: 'calc(100vh - 56px)',
    height: '200vh',
    // width: '100%',
    backgroundImage: `url(/img/landing_bg.png)`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    // backgroundColor: "rgba(48, 48, 48, 0.85)",
    // backgroundBlendMode: "darken",
  },
  bgColor: {
    // height: 'calc(100vh - 56px)',
    height: '200vh',
    // width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  bgTitle: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    width: '100%',
    height: '45vh',
    marginTop: '20vh',
  },
  title: {
    textAlign: 'center',
    fontSize: '2rem',
  },
  description: {
    textAlign: 'center',
    fontSize: '1rem',
  },
  button: {
    paddingTop: '40px',
    textAlign: 'center',
  },
  sentence: {
    marginBottom: '2rem',
  },
  explanation: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    marginTop: '27vh',
    padding: '20px',
  },
}));

export const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

function Landing() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.bgImage}>
      <div className={classes.bgColor}>
        <div className={classes.bgTitle}>
          <p className={classes.title}>Depengers</p>
          <p className={classes.description}>{t(`text.landing`)}</p>
          <div className={classes.button}>
            <Link to='/app/get-started' style={{ textDecoration: 'none' }}>
              <ColorButton variant='contained' color='primary'>
                {t(`button.start`)}
              </ColorButton>
            </Link>
          </div>
          <div className={classes.explanation}>
            <Typography component='h2' variant='h4' gutterBottom>
              {t(`text.whatIsDepengers`)}
            </Typography>
            <Typography
              variant='subtitle1'
              gutterBottom
              className={classes.sentence}
            >
              <p>{t(`text.whatIsDepengersText1`)}</p>
              <p>{t(`text.whatIsDepengersText2`)}</p>
              <p>{t(`text.whatIsDepengersText3`)}</p>
              <p>{t(`text.whatIsDepengersText4`)}</p>
              <p>{t(`text.whatIsDepengersText5`)}</p>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Landing);
