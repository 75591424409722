import React from 'react';
import { connect } from 'react-redux';
import { addWatched, removeWatched } from '../../redux/actions';
import { IMG_BY_MOVIE, MOVIE_TITLES } from '../../Constants';
import {
  makeStyles,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import { Typography, Fab } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FilmIcon } from './Main';
import { ColorButton } from './Landing';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Link } from 'react-router-dom';

const buttonTheme = createMuiTheme({
  palette: { primary: { main: 'rgba(255,255,255,0.25)' } },
});

const useStyles = makeStyles((theme) => ({
  bgImage: {
    // height: "calc(100vh - 56px)",
    // width: "100%",
    backgroundImage: `url(/img/get_started_bg.png)`,
    backgroundSize: 'cover',
    // backgroundRepeat: "repeat-y",
    // backgroundColor: "rgba(48, 48, 48, 0.85)",
    // backgroundBlendMode: "darken",
  },
  root: {
    display: 'flex',
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: '20vh',
    height: '100%',
  },
  controls: {
    // display: "flex",
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  explain: {
    textAlign: 'center',
  },
  button: {
    textAlign: 'right',
    paddingTop: '30px',
    paddingBottom: '60px',
  },
  text: {
    margin: '0',
    paddingTop: '14px',
  },
  nextButton: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function GetStarted(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.bgImage}>
      <div className={classes.explain}>
        <p className={classes.text}>{t(`text.getStart`)}</p>
        <p>{t(`text.laterChange`)}</p>
        <p>{t(`text.pleaseReload`)}</p>
      </div>
      {props.watchList.map((movie) => {
        return (
          <div key={movie.title}>
            <Card className={classes.root}>
              <CardMedia
                component='img'
                className={classes.cover}
                src={IMG_BY_MOVIE[movie.title]}
                title={t(`movies.${movie.title}`)}
              />
              <div className={classes.details}>
                <CardContent className={classes.content}>
                  <Typography component='h5' variant='h5'>
                    {t(`movies.${movie.title}`)}
                  </Typography>
                </CardContent>
                <div className={classes.controls}>
                  <MuiThemeProvider theme={buttonTheme}>
                    <Fab
                      color='primary'
                      aria-label='Film'
                      // className={classes.filmIcon}
                      onClick={() => {
                        movie.watched
                          ? props.removeWatched(movie.title)
                          : props.addWatched(movie.title);
                      }}
                    >
                      <FilmIcon
                        htmlColor={movie.watched ? '#FC4158' : '#d1d1d1'}
                        viewBox='-0.5 -23 187 187'
                        fontSize='large'
                      />
                    </Fab>
                  </MuiThemeProvider>
                </div>
              </div>
            </Card>
          </div>
        );
      })}

      <div className={classes.button}>
        <Link to='/app/select-list' style={{ textDecoration: 'none' }}>
          <ColorButton
            variant='contained'
            color='primary'
            className={classes.nextButton}
          >
            {t(`button.next`)}
          </ColorButton>
        </Link>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    watchList: MOVIE_TITLES.map((title) => ({ ...state.movies[title], title }))
      // .filter(movie => !movie.watched && !movie.skipped)
      .filter((movie) => {
        // At least one Hero selected and intersection between state.heros and movie.heros exists
        return (
          !state.heros.length ||
          movie.hero.filter((h) => state.heros.includes(h)).length
        );
      }),
  }),
  { addWatched, removeWatched }
)(GetStarted);
