import React, { useState } from 'react';
import { ColorButton } from './Landing';
import { connect } from 'react-redux';
import {
  completeLanding,
  setEntryList,
  setMiddleList,
  setFullList,
} from '../../redux/actions';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  bgImage: {
    // height: 'calc(100vh - 56px)',
    // width: "100%",
    backgroundImage: `url(/img/get_started_bg.png)`,
    backgroundSize: 'cover',
    // backgroundRepeat: "repeat-y",
    // backgroundColor: "rgba(48, 48, 48, 0.85)",
    // backgroundBlendMode: "darken",
  },
  explain: {
    textAlign: 'center',
  },
  text: {
    margin: '0',
    paddingTop: '14px',
  },
  button: {
    textAlign: 'center',
    padding: '30px',
  },
  navButton: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '20px',
    paddingBottom: '60px',
  },
  backButton: {
    textAlign: 'left',
  },
  compButton: {
    textAlign: 'right',
    paddingBottom: '60px',
  },
}));

function SelectList(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [movielist, SetMovieList] = useState('');

  const handleEntryList = () => {
    props.setEntryList();
    SetMovieList('entryList');
  };

  const handleMiddleList = () => {
    props.setMiddleList();
    SetMovieList('middleList');
  };

  const handleFullList = () => {
    props.setFullList();
    SetMovieList('fullList');
  };

  return (
    <div className={classes.bgImage}>
      <div className={classes.explain}>
        <p className={classes.text}>{t(`text.selectSet`)}</p>
        <p>{t(`text.laterChange`)}</p>
      </div>
      <div className={classes.button}>
        <Button
          variant='contained'
          color={movielist === 'entryList' ? 'secondary' : 'primary'}
          onClick={() => handleEntryList()}
        >
          {t(`button.entryList`)}
        </Button>
        <p>{t(`text.entryText`)}</p>
        <p>{t(`text.entryRecommend`)}</p>
      </div>
      <div className={classes.button}>
        <Button
          variant='contained'
          color={movielist === 'middleList' ? 'secondary' : 'primary'}
          onClick={() => handleMiddleList()}
        >
          {t(`button.middleList`)}
        </Button>
        <p>{t(`text.middleText`)}</p>
        <p>{t(`text.middleRecommend`)}</p>
      </div>
      <div className={classes.button}>
        <Button
          variant='contained'
          color={movielist === 'fullList' ? 'secondary' : 'primary'}
          onClick={() => handleFullList()}
        >
          {t(`button.fullList`)}
        </Button>
        <p>{t(`text.fullText`)}</p>
        <p>{t(`text.fullRecommend`)}</p>
      </div>
      <div className={classes.navButton}>
        <div className={classes.backButton}>
          {props.settings.landingCompleted ? (
            <></>
          ) : (
            <Link to='/app/get-started' style={{ textDecoration: 'none' }}>
              <ColorButton variant='contained' color='primary'>
                {t(`button.back`)}
              </ColorButton>
            </Link>
          )}
        </div>
        <div className={classes.compButton}>
          {movielist === '' ? (
            <ColorButton
              variant='contained'
              color='primary'
              disabled={movielist === ''}
              onClick={() => props.completeLanding()}
            >
              {t(`button.complete`)}
            </ColorButton>
          ) : (
            <Link to='/app' style={{ textDecoration: 'none' }}>
              <ColorButton
                variant='contained'
                color='primary'
                disabled={movielist === ''}
                onClick={() => props.completeLanding()}
              >
                {t(`button.complete`)}
              </ColorButton>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default connect((state) => ({ settings: state.settings }), {
  completeLanding,
  setEntryList,
  setMiddleList,
  setFullList,
})(SelectList);
